<template>
<div class="card-box-full">
  <div class="card-box-header">
    Domain Lookup
  </div>

    <div class="form-group row" style="margin-top: 15px; padding: 5px">
      <div class="col-md-12">
        <div class="input-group">

          <input class="form-control" v-model="domain" type="url" autocomplete="on" placeholder="Enter a domain" :disabled="isExecuting" @keyup.enter="execute()">
          <div class="input-group-append" color="primary">
            <CButton @click="execute()" color="primary" :disabled="isExecuting || domain.length == 0" style="width: 80px">
              Lookup
            </CButton>
          </div>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-if="isExecuting" class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 15px">
          <span class="sr-only"></span>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-if="errorMessage">
          <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-if="domainData" style="margin-top: 15px">
          <hr>
          <table cellpadding="6">
            <tr>
              <td valign="middle">
                <h6>Domain</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ domainData.domain }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>TLD</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ domainData.tld }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Times Seen</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ domainData.times_seen.toLocaleString() }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>First Seen</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">
                  <timeago :datetime="domainData.first_seen"></timeago>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Last Seen</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">
                  <timeago :datetime="domainData.last_seen"></timeago>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Reputation Expires</h6>
              </td>
              <td>
                <div v-if="domainData.positive_reputation == null" style="margin-top: -10px">
                  <span class="badge badge-pill badge-warning">
                    Domain doesn't have reputation
                  </span>
                </div>
                <div v-else>
                  <div style="font-family: monospace; margin-top: -10px">
                    <timeago :datetime="domainData.reputation_expiration"></timeago>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Reputation</h6>
              </td>
              <td>
                <div style="margin-top: -10px">
                  <span v-if="domainData.positive_reputation == null" class="badge badge-pill badge-warning">
                    Domain doesn't have reputation
                  </span>
                  <span v-else-if="domainData.positive_reputation" class="badge badge-pill badge-success">
                    Positive
                  </span>
                  <span v-else class="badge badge-pill badge-danger">
                    Negative
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Confidence</h6>
              </td>
              <td>
                <div style="margin-top: -10px">
                  <span v-if="domainData.high_confidence == null" class="badge badge-pill badge-warning">
                    Domain doesn't have reputation
                  </span>
                  <span v-else-if="domainData.high_confidence" class="badge badge-pill badge-dark">
                    High
                  </span>
                  <span v-else class="badge badge-pill badge-light">
                    Low
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Overridden</h6>
              </td>
              <td>
                <div style="margin-top: -10px">
                  <span v-if="domainData.overridden" class="badge badge-pill badge-dark">
                    Yes
                  </span>
                  <span v-else class="badge badge-pill badge-light">
                    No
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <CButton @click="clickedWhoisLookup()" color="info" style="width: 100%">
                  Perform a whois lookup
                </CButton>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <CButton @click="clickedViewProfile()" color="info" style="width: 100%">
                  View this domains profile
                </CButton>
              </td>
            </tr>
          </table>
        </div>

      </div>
    </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      domain: "",
      errorMessage: null,
      isExecuting: false,
      domainData: null
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedViewProfile() {
      this.$router.push({
        path: "/sms-profile/domain/" + encodeURIComponent(this.domain)
      });
    },
    //--------------------------------------------------------------------------
    clickedWhoisLookup() {
      this.$router.push({
        path: "/domain-reputation/whois/" + encodeURIComponent(this.domain)
      });
    },
    //--------------------------------------------------------------------------
    execute() {
      if (this.domain.length == 0) {
        return;
      } else {
        this.domain = this.domain.toLowerCase();
      }

      if (this.isExecuting) {
        return;
      } else {
        this.domainData = null;
        this.errorMessage = null;
        this.isExecuting = true;
      }

      // Perform the lookup
      var that = this;
      var method = "GET";
      var url = "https://domain-reputation.platform.robocalls.ai/domain?query=" + encodeURIComponent(this.domain);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isExecuting = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isExecuting = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isExecuting = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.domain) {
        this.errorMessage = "No domain data was returned";
        return;
      }
      this.domainData = response.domain;
    },
  },
  mounted() {
    if (this.$route.params.domain) {
      this.domain = this.$route.params.domain;
      this.execute();
    }
  }
}
</script>
